/* eslint-disable @typescript-eslint/no-explicit-any */
import { Event, Integration, StackFrame, WrappedFunction } from '@sentry/types';

import { dynamicRequire, isNodeEnv } from './node';
import { snipLine } from './string';

/** Internal */
interface SentryGlobal {
  Sentry?: {
    Integrations?: Integration[];
  };
  SENTRY_ENVIRONMENT?: string;
  SENTRY_DSN?: string;
  SENTRY_RELEASE?: {
    id?: string;
  };
  __SENTRY__: {
    globalEventProcessors: any;
    hub: any;
    logger: any;
  };
}

const fallbackGlobalObject = {};

/**
 * Safely get global scope object
 *
 * @returns Global scope object
 */
export function getGlobalObject<T>(): T & SentryGlobal {
  return (isNodeEnv()
    ? global
    : typeof window !== 'undefined'
    ? window
    : typeof self !== 'undefined'
    ? self
    : fallbackGlobalObject) as T & SentryGlobal;
}

/**
 * Determines if running in react native
 */
export function isReactNative(): boolean {
  return getGlobalObject<Window>().navigator?.product === 'ReactNative';
}

/**
 * Extended Window interface that allows for Crypto API usage in IE browsers
 */
interface MsCryptoWindow extends Window {
  msCrypto?: Crypto;
}

/**
 * UUID4 generator
 *
 * @returns string Generated UUID4.
 */
export function uuid4(): string {
  const global = getGlobalObject() as MsCryptoWindow;
  const crypto = global.crypto || global.msCrypto;

  if (!(crypto === void 0) && crypto.getRandomValues) {
    // Use window.crypto API if available
    const arr = new Uint16Array(8);
    crypto.getRandomValues(arr);

    // set 4 in byte 7
    // eslint-disable-next-line no-bitwise
    arr[3] = (arr[3] & 0xfff) | 0x4000;
    // set 2 most significant bits of byte 9 to '10'
    // eslint-disable-next-line no-bitwise
    arr[4] = (arr[4] & 0x3fff) | 0x8000;

    const pad = (num: number): string => {
      let v = num.toString(16);
      while (v.length < 4) {
        v = `0${v}`;
      }
      return v;
    };

    return (
      pad(arr[0]) + pad(arr[1]) + pad(arr[2]) + pad(arr[3]) + pad(arr[4]) + pad(arr[5]) + pad(arr[6]) + pad(arr[7])
    );
  }
  // http://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid-in-javascript/2117523#2117523
  return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, c => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

/**
 * Parses string form of URL into an object
 * // borrowed from https://tools.ietf.org/html/rfc3986#appendix-B
 * // intentionally using regex and not <a/> href parsing trick because React Native and other
 * // environments where DOM might not be available
 * @returns parsed URL object
 */
export function parseUrl(
  url: string,
): {
  host?: string;
  path?: string;
  protocol?: string;
  relative?: string;
} {
  if (!url) {
    return {};
  }

  const match = url.match(/^(([^:/?#]+):)?(\/\/([^/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?$/);

  if (!match) {
    return {};
  }

  // coerce to undefined values to empty string so we don't get 'undefined'
  const query = match[6] || '';
  const fragment = match[8] || '';
  return {
    host: match[4],
    path: match[5],
    protocol: match[2],
    relative: match[5] + query + fragment, // everything minus origin
  };
}

/**
 * Extracts either message or type+value from an event that can be used for user-facing logs
 * @returns event's description
 */
export function getEventDescription(event: Event): string {
  if (event.message) {
    return event.message;
  }
  if (event.exception && event.exception.values && event.exception.values[0]) {
    const exception = event.exception.values[0];

    if (exception.type && exception.value) {
      return `${exception.type}: ${exception.value}`;
    }
    return exception.type || exception.value || event.event_id || '<unknown>';
  }
  return event.event_id || '<unknown>';
}

/** JSDoc */
interface ExtensibleConsole extends Console {
  [key: string]: any;
}

/** JSDoc */
export function consoleSandbox(callback: () => any): any {
  const global = getGlobalObject<Window>();
  const levels = ['debug', 'info', 'warn', 'error', 'log', 'assert'];

  if (!('console' in global)) {
    return callback();
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const originalConsole = (global as any).console as ExtensibleConsole;
  const wrappedLevels: { [key: string]: any } = {};

  // Restore all wrapped console methods
  levels.forEach(level => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (level in (global as any).console && (originalConsole[level] as WrappedFunction).__sentry_original__) {
      wrappedLevels[level] = originalConsole[level] as WrappedFunction;
      originalConsole[level] = (originalConsole[level] as WrappedFunction).__sentry_original__;
    }
  });

  // Perform callback manipulations
  const result = callback();

  // Revert restoration to wrapped state
  Object.keys(wrappedLevels).forEach(level => {
    originalConsole[level] = wrappedLevels[level];
  });

  return result;
}

/**
 * Adds exception values, type and value to an synthetic Exception.
 * @param event The event to modify.
 * @param value Value of the exception.
 * @param type Type of the exception.
 * @hidden
 */
export function addExceptionTypeValue(event: Event, value?: string, type?: string): void {
  event.exception = event.exception || {};
  event.exception.values = event.exception.values || [];
  event.exception.values[0] = event.exception.values[0] || {};
  event.exception.values[0].value = event.exception.values[0].value || value || '';
  event.exception.values[0].type = event.exception.values[0].type || type || 'Error';
}

/**
 * Adds exception mechanism to a given event.
 * @param event The event to modify.
 * @param mechanism Mechanism of the mechanism.
 * @hidden
 */
export function addExceptionMechanism(
  event: Event,
  mechanism: {
    [key: string]: any;
  } = {},
): void {
  // TODO: Use real type with `keyof Mechanism` thingy and maybe make it better?
  try {
    // @ts-ignore Type 'Mechanism | {}' is not assignable to type 'Mechanism | undefined'
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    event.exception!.values![0].mechanism = event.exception!.values![0].mechanism || {};
    Object.keys(mechanism).forEach(key => {
      // @ts-ignore Mechanism has no index signature
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      event.exception!.values![0].mechanism[key] = mechanism[key];
    });
  } catch (_oO) {
    // no-empty
  }
}

/**
 * A safe form of location.href
 */
export function getLocationHref(): string {
  try {
    return document.location.href;
  } catch (oO) {
    return '';
  }
}

const INITIAL_TIME = Date.now();
let prevNow = 0;

/**
 * Cross platform compatible partial performance implementation
 */
interface CrossPlatformPerformance {
  timeOrigin: number;
  /**
   * Returns the current timestamp in ms
   */
  now(): number;
}

const performanceFallback: CrossPlatformPerformance = {
  now(): number {
    let now = Date.now() - INITIAL_TIME;
    if (now < prevNow) {
      now = prevNow;
    }
    prevNow = now;
    return now;
  },
  timeOrigin: INITIAL_TIME,
};

/**
 * Performance wrapper for react native as performance.now() has been found to start off with an unusual offset.
 */
function getReactNativePerformanceWrapper(): CrossPlatformPerformance {
  const INITIAL_OFFSET = performance.now();

  return {
    now(): number {
      return performance.now() - INITIAL_OFFSET;
    },
    timeOrigin: INITIAL_TIME,
  };
}

export const crossPlatformPerformance: CrossPlatformPerformance = ((): CrossPlatformPerformance => {
  // React Native's performance.now() starts with a gigantic offset, so we need to wrap it.
  if (isReactNative()) {
    return getReactNativePerformanceWrapper();
  }

  if (isNodeEnv()) {
    try {
      const perfHooks = dynamicRequire(module, 'perf_hooks') as { performance: CrossPlatformPerformance };
      return perfHooks.performance;
    } catch (_) {
      return performanceFallback;
    }
  }

  const { performance } = getGlobalObject<Window>();

  if (!performance || !performance.now) {
    return performanceFallback;
  }

  // Polyfill for performance.timeOrigin.
  //
  // While performance.timing.navigationStart is deprecated in favor of performance.timeOrigin, performance.timeOrigin
  // is not as widely supported. Namely, performance.timeOrigin is undefined in Safari as of writing.
  if (performance.timeOrigin === undefined) {
    // As of writing, performance.timing is not available in Web Workers in mainstream browsers, so it is not always a
    // valid fallback. In the absence of a initial time provided by the browser, fallback to INITIAL_TIME.
    // @ts-ignore ignored because timeOrigin is a readonly property but we want to override
    // eslint-disable-next-line deprecation/deprecation
    performance.timeOrigin = (performance.timing && performance.timing.navigationStart) || INITIAL_TIME;
  }

  return performance;
})();

/**
 * Returns a timestamp in seconds with milliseconds precision since the UNIX epoch calculated with the monotonic clock.
 */
export function timestampWithMs(): number {
  return (crossPlatformPerformance.timeOrigin + crossPlatformPerformance.now()) / 1000;
}

// https://semver.org/#is-there-a-suggested-regular-expression-regex-to-check-a-semver-string
const SEMVER_REGEXP = /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/;

/**
 * Represents Semantic Versioning object
 */
interface SemVer {
  major?: number;
  minor?: number;
  patch?: number;
  prerelease?: string;
  buildmetadata?: string;
}

/**
 * Parses input into a SemVer interface
 * @param input string representation of a semver version
 */
export function parseSemver(input: string): SemVer {
  const match = input.match(SEMVER_REGEXP) || [];
  const major = parseInt(match[1], 10);
  const minor = parseInt(match[2], 10);
  const patch = parseInt(match[3], 10);
  return {
    buildmetadata: match[5],
    major: isNaN(major) ? undefined : major,
    minor: isNaN(minor) ? undefined : minor,
    patch: isNaN(patch) ? undefined : patch,
    prerelease: match[4],
  };
}

const defaultRetryAfter = 60 * 1000; // 60 seconds

/**
 * Extracts Retry-After value from the request header or returns default value
 * @param now current unix timestamp
 * @param header string representation of 'Retry-After' header
 */
export function parseRetryAfterHeader(now: number, header?: string | number | null): number {
  if (!header) {
    return defaultRetryAfter;
  }

  const headerDelay = parseInt(`${header}`, 10);
  if (!isNaN(headerDelay)) {
    return headerDelay * 1000;
  }

  const headerDate = Date.parse(`${header}`);
  if (!isNaN(headerDate)) {
    return headerDate - now;
  }

  return defaultRetryAfter;
}

/**
 * This function adds context (pre/post/line) lines to the provided frame
 *
 * @param lines string[] containing all lines
 * @param frame StackFrame that will be mutated
 * @param linesOfContext number of context lines we want to add pre/post
 */
export function addContextToFrame(lines: string[], frame: StackFrame, linesOfContext: number = 5): void {
  const lineno = frame.lineno || 0;
  const maxLines = lines.length;
  const sourceLine = Math.max(Math.min(maxLines, lineno - 1), 0);

  frame.pre_context = lines
    .slice(Math.max(0, sourceLine - linesOfContext), sourceLine)
    .map((line: string) => snipLine(line, 0));

  frame.context_line = snipLine(lines[Math.min(maxLines - 1, sourceLine)], frame.colno || 0);

  frame.post_context = lines
    .slice(Math.min(sourceLine + 1, maxLines), sourceLine + 1 + linesOfContext)
    .map((line: string) => snipLine(line, 0));
}

/**
 * Strip the query string and fragment off of a given URL or path (if present)
 *
 * @param urlPath Full URL or path, including possible query string and/or fragment
 * @returns URL or path without query string or fragment
 */
export function stripUrlQueryAndFragment(urlPath: string): string {
  // eslint-disable-next-line no-useless-escape
  return urlPath.split(/[\?#]/, 1)[0];
}
